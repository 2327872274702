/**
 * FilterDropdown - styles
 */

import {
	styleForButton,
	styleForLabel,
	styleForSelect,
} from 'components/Typography';
import styled from 'styled-components';
import { mediaQueries } from 'theme';

export const FilterButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	${mediaQueries.phone} {
		display: none;
	}
`;

export const FilterBlock = styled.div`
	position: relative;
`;

type SelectFilterContainerProps = {
	isOpen?: boolean;
};

export const FilterButton = styled.button<SelectFilterContainerProps>`
	${styleForSelect}
	padding: 7px 9px;
	margin-top: ${(props) => props.theme.spacing.space2}rem;
	margin-right: ${(props) => props.theme.spacing.space2}rem;
	cursor: pointer;
	display flex;

	&:hover {
		transition: background-color 100ms ease;
		background-color: ${(props) => props.theme.colors.blue2}a0;
		border: 1px solid ${(props) => props.theme.colors.primary};
	}
	
	svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
		align-self: center;
		flex: 0 0 auto;
		margin-left: 10px;
		pointer-events: none;
	}

	.unselectable {
    pointer-events: none;
}
`;

export const FilterOptionsContainer = styled.fieldset`
	position: absolute;
	top: 5rem;
	display: flex;
	flex-direction: column;
	padding: 0;
	min-width: 300px;
	max-height: 397px;
	overflow-x: hidden;
	overflow-y: scroll;
	z-index: 100;
	border: transparent;
	border-radius: 8px;
	background-color: rgb(var(--colour-elevation-1, 255, 255, 255));
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);

	legend {
		position: absolute !important;
		display: block;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		white-space: nowrap;
		visibility: visible;
		border: 0;
	}
`;

export const FilterLabel = styled.label`
	cursor: pointer;
	${styleForLabel}
	padding: 0.8rem 1rem;
	margin: 0;
	display: flex;
	justify-content: space-between;
	svg circle {
		stroke-width: 1px;
	}
	&:hover {
		transition: background-color 100ms ease;
		background-color: ${(props) => props.theme.colors.blue2}a0;
		span {
			text-decoration: underline;
		}
		svg circle {
			stroke-width: 2px;
		}
	}
	&:focus-within {
		background-color: ${(props) => props.theme.colors.blue2};
		outline: 1px solid ${(props) => props.theme.colors.primary};
		outline-offset: -1px;
	}

	&.first:focus-within {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&.last:focus-within {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	background-color: white;

	input {
		-webkit-appearance: none;
		appearance: none;
		clippath: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
		border: 0;
	}
	input:focus {
		outline: none;
	}
`;

export const TimeIntervalDropdown = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
	width: 415px;
	.separator {
		border-top: 1px solid #bfbfbf;
		width: 95%;
		margin-bottom: 16px;
	}
	${styleForButton}
	${mediaQueries.phone} {
		width: auto;
		.separator {
			width: 100%;
		}
	}
`;

export const CheckboxesInline = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	align-items: center;
`;
