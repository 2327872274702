/**
 * SearchByListngPage - styles
 */

import Button from 'components/Button';
import { styleForH3, styleForH4, styleForLabel } from 'components/Typography';
import styled from 'styled-components';
import { mediaQueries } from 'theme/media-queries';
import { px2rem } from 'theme/utils';

export const SearchOverlappBlock = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	padding: ${(props) => props.theme.spacing.space4}rem;

	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
	margin-top: -5rem;

	.as-h3 {
		${styleForH3}
		color: ${(props) => props.theme.colors.primary};
		margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	}
	${mediaQueries.phone} {
		.as-h3 {
			font-size: 22px;
			margin-bottom: ${(props) => props.theme.spacing.space1}rem;
		}
		padding: ${(props) => props.theme.spacing.space4}rem
			${(props) => props.theme.spacing.space2}rem;
	}

	.as-h4 {
		${styleForH4}
		color: ${(props) => props.theme.colors.primary};
		margin-bottom: 0;
	}
	${mediaQueries.phone} {
		.as-h4 {
			font-size: 18px;
			margin-bottom: ${(props) => props.theme.spacing.space1}rem;
		}
		padding: ${(props) => props.theme.spacing.space4}rem
			${(props) => props.theme.spacing.space2}rem;
	}
`;

export const FiltersContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing.space4}rem;
`;

export const ResultMessageHelper = styled.div`
	background-color: ${(props) => props.theme.colors.grey5};
	padding: ${(props) => props.theme.spacing.space2}rem
		${(props) => props.theme.spacing.space3}rem;

	margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	border-radius: 8px;
	span {
		${styleForH4}
		margin-bottom: 14px;
	}

	.span-flex {
		display: flex;
		flex-direction: column;
	}
	.as-label {
		${styleForLabel}
		margin-bottom: 0;
	}
`;

export const StyledSpan = styled.span`
	font-weight: bold !important;
`;

export const SearchInputWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	position: relative;
	input {
		width: 100%;
	}
	margin-bottom: ${(props) => props.theme.spacing.space2}rem;
	.search-close-icon {
		width: 24px;
		height: 24px;
		position: absolute;
		right: 95px;
		${mediaQueries.phone} {
			right: 12px;
		}
		top: 12px;
		cursor: pointer;
	}
	.autocomplete-section {
		width: 100%;
		margin-right: 1rem;
		${mediaQueries.phone} {
			margin-right: 0;
		}
	}

	button {
		margin-right: 0;
	}
`;

export const PillsBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${(props) => props.theme.spacing.space3}rem;
`;

export const PillResetButton = styled.button`
	${(props) => props.theme.typography.style9.getStyle()};
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colors.white};
	margin-bottom: ${(props) => props.theme.spacing.space1}rem;
	background-color: #6b6b6b;
	border: none;
	cursor: pointer;
	span {
		margin-top: 2px;
	}
	&:hover {
		background-color: ${(props) => props.theme.palette.text.primary};
	}
	&:focus {
		outline-color: ${(props) => props.theme.palette.text.primary};
	}
`;

export const AllFilterButton = styled(Button)`
	display: none;
	width: 100%;
	margin-top: ${(props) => props.theme.spacing.space1}rem;
	padding-top: ${px2rem(12)};
	padding-bottom: ${px2rem(12)};
	${mediaQueries.phone} {
		display: flex;
		justify-content: center;
	}
`;
